export const RoutePaths = {
  Base: '/',
  Home: {
    Base: '/',
    Circles: {
      Base: '/circle/:id',
    },
  },
  Signin: '/signin',
};

export const RouteIds = {
  Root: 'root',
  Circle: 'circle',
};
