import { Typography } from '@mui/material';
import { useRouteLoaderData } from 'react-router-dom';
import { RouteIds } from '../Constants';
import { AuthData } from '../services/authService';

export const Home = () => {
  const authData = useRouteLoaderData(RouteIds.Root) as AuthData;

  return <Typography>Welcome to Circles {authData.user.name}!</Typography>;
};
