import { Paths } from 'models/Paths';
import { secureFetch } from '../Util';
import { IEntry } from 'models/Entry';

/**
 * Gets the prompt for this circle on this selected day
 * @param circleId
 * @param selectedDate
 * @returns
 */
export const getPromptForDay = async (circleId: string, selectedDate: string) => {
  // fetch the picked prompt for this day
  const res = await secureFetch(`/api/${Paths.Prompt.Base}/get/${circleId}/${selectedDate}`, 'GET');

  if (res.ok) {
    const prompt = await res.json();
    return prompt;
  } else if (res.status === 400) {
    const err = await res.json();
    return err;
  } else {
    return '';
  }
};

export const editEntry = async (entry: IEntry) => {
  const res = await secureFetch(`/api/${Paths.Journal.Base}/${Paths.Journal.Edit}`, 'POST', {
    entry: entry,
  });

  return res.ok;
};
