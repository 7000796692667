import React, { useRef, useState } from 'react';
import { useAuth } from '../contexts/Auth';
import { Box, Button, CircularProgress, IconButton, InputAdornment, Modal, Stack, TextField, Typography } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

export const SignIn = () => {
  const [loading, isLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState<string | undefined>(undefined);
  const [forgotPasswordModal, setForgotPasswordModal] = useState(false);

  const ref = useRef(null);

  const auth = useAuth();
  const navigate = useNavigate();

  const signIn = async () => {
    isLoading(true);
    const error = await auth.signIn(email, password);
    if (error) {
      setLoginError(error);
      isLoading(false);
    } else {
      navigate('/');
    }
  };

  const forgotPassword = async () => {
    const apiURL = `/api/auth/forgotPassword`;

    const res = await fetch(apiURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: email,
      }),
    });
    if (res.ok) {
      return console.log('Check your email!');
    } else {
      throw new Error(await res.text());
    }
  };

  return (
    <Stack>
      <Typography>Sign in</Typography>
      <Stack>
        <TextField label="Email" type="text" value={email} onChange={(e) => setEmail(e.target.value)} />
        <TextField
          label="Password"
          type={showPassword ? 'text' : 'password'}
          autoComplete="current-password"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton aria-label="toggle password visibility" onClick={() => setShowPassword(!showPassword)}>
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          variant="outlined"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          helperText={loginError}
        />
        {loading ? <CircularProgress /> : <Button onClick={signIn}>Sign in</Button>}
      </Stack>

      <Button onClick={() => setForgotPasswordModal(true)} ref={ref}>
        Forgot password?
      </Button>
      <Modal
        open={forgotPasswordModal}
        onClose={() => {
          setForgotPasswordModal(false);
        }}
      >
        <Box>
          <Typography>Forgot password?</Typography>
          <Stack>
            <Typography>If this is the email you used to register, we will send a link to reset your password.</Typography>
            <TextField label="Email" type="text" value={email} onChange={(e) => setEmail(e.target.value)} />
          </Stack>
          <Button
            onClick={() => {
              setForgotPasswordModal(false);
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              setForgotPasswordModal(false);
              forgotPassword();
            }}
          >
            Confirm
          </Button>
        </Box>
      </Modal>
    </Stack>
  );
};
