import React, { ChangeEvent, useRef, useState } from 'react';
import { useAuth } from '../../contexts/Auth';
import { getCurrentDate, getMonthFromDatestring, secureFetch } from '../../Util';
import { ICircle, IEditCircle } from 'models/Circle';
import { Paths } from 'models/Paths';
import { IEntry } from 'models/Entry';
import { DateTime } from 'luxon';
import { useNavigate, useRouteLoaderData } from 'react-router-dom';
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Stack,
  TextField,
  Typography,
  IconButton,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { RouteIds } from '../../Constants';

export const CircleOptions = () => {
  const auth = useAuth();

  const circle = useRouteLoaderData(RouteIds.Circle) as ICircle;

  const [invitedUser, setInvitedUser] = useState('');
  const [circleName, setCircleName] = useState(circle?.name ?? '');
  const [startdate, setStartdate] = useState(circle?.startdate ?? '');
  const [timezone, setTimezone] = useState(circle?.timezone ?? 'UTC');
  const [deletionModal, setDeletionModal] = useState(false);

  const [loadingExport, setLoadingExport] = useState(false);
  const [loadingImport, setLoadingImport] = useState(false);

  const navigate = useNavigate();

  if (circle === undefined) {
    throw Error();
  }

  const circleId = circle.id;

  const deleteRef = useRef(null);

  const inviteUser = () => {
    secureFetch(`/api/${Paths.Circle.Base}/${Paths.Circle.Invite}`, 'POST', {
      circle: circleId,
      email: auth.authData?.user.id ?? '',
      invited: invitedUser,
    });
  };

  const editCircle = () => {
    const editedCircle: IEditCircle = {
      id: circleId,
      name: circleName,
      startdate: startdate,
      timezone: timezone,
    };

    secureFetch(`/api/${Paths.Circle.Base}/${Paths.Circle.Edit}`, 'POST', {
      circle: editedCircle,
      email: auth.authData?.user.id ?? '',
    });
  };

  const deleteCircle = () => {
    secureFetch(`/api/${Paths.Circle.Base}/delete/${circleId}`, 'DELETE').then((res) => {
      if (res.ok) {
        auth.refreshUser();
        navigate('/Home');
      }
    });
  };

  const exportCircle = (): void => {
    setLoadingExport(true);
    secureFetch(`/api/${Paths.Journal.Base}/export/${circleId}`, 'GET').then((res) => {
      if (res.ok) {
        res
          .blob()
          .then((blob) => URL.createObjectURL(blob))
          .then((url) => {
            window.open(url, '_blank');
            URL.revokeObjectURL(url);
          });
        setLoadingExport(false);
      }
    });
  };

  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);

  const handleFileEvent = (e: ChangeEvent<HTMLInputElement>) => {
    const chosenFiles: File[] = Array.prototype.slice.call(e.target.files);
    console.log(chosenFiles);
    const uploaded = [...uploadedFiles];
    chosenFiles.forEach((file) => {
      if (uploaded.findIndex((f) => f.name === file.name) === -1) {
        uploaded.push(file);
      }
    });
    setUploadedFiles(uploaded);
  };

  const submitImport = () => {
    setLoadingImport(true);
    Promise.all(
      uploadedFiles.map(async (file) => {
        // turn the file into an entry object
        const contents = await file.text();

        const datestring = DateTime.fromISO(file.name).toISODate();

        if (datestring === null) {
          return;
        }

        const entry: IEntry = {
          id: datestring,
          month: getMonthFromDatestring(datestring),
          content: contents,
          circle: circleId,
          email: 'dummy',
        };

        return entry;
      })
    ).then(async (entries) => {
      // with all the entries, split into chunks of 100 and send to backend
      entries = entries.filter((e) => e !== undefined);

      for (let i = 0; i < entries.length; i += 100) {
        // Get a chunk of the array
        const chunk = entries.slice(i, i + 100);

        const res = await secureFetch(`/api/${Paths.Journal.Base}/${Paths.Journal.Import}`, 'POST', chunk);
        if (res.ok) {
          console.log('successfully uploaded entries!');
        }
      }

      setLoadingImport(false);
    });
  };

  return (
    <Box>
      <Typography variant="h3">Circle Members</Typography>
      {circle.members.map((member) => (
        <Typography key={member}>{member}</Typography>
      ))}
      <Stack spacing={2}>
        <TextField
          label="Invite User"
          value={invitedUser}
          onChange={(e) => setInvitedUser(e.target.value)}
          placeholder="invited-email@email.com"
          variant="outlined"
          fullWidth
        />
        <Button variant="contained" color="primary" onClick={inviteUser}>
          Invite
        </Button>
      </Stack>

      <Stack spacing={2}>
        <TextField
          label="Circle Name"
          value={circleName}
          onChange={(e) => setCircleName(e.target.value)}
          placeholder="CircleName"
          variant="outlined"
          fullWidth
        />
      </Stack>

      <Stack spacing={2}>
        <TextField
          label="Start Date"
          value={startdate}
          onChange={(e) => setStartdate(e.target.value)}
          placeholder={getCurrentDate(timezone)}
          variant="outlined"
          fullWidth
        />
      </Stack>

      <FormControl variant="outlined" fullWidth>
        <InputLabel>Choose timezone</InputLabel>
        <Select value={timezone} onChange={(e) => setTimezone(e.target.value)} label="Choose timezone">
          {Intl.supportedValuesOf('timeZone').map((v) => (
            <MenuItem key={v} value={v}>
              {v}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Button variant="contained" color="primary" onClick={editCircle}>
        Save changes
      </Button>

      <Stack spacing={2}>
        <Button variant="contained" color="primary" onClick={exportCircle} disabled={loadingExport}>
          Export my entries
        </Button>
      </Stack>

      <input type="file" multiple onChange={handleFileEvent} />

      <Stack spacing={2}>
        {uploadedFiles.map((file) => (
          <Box key={file.name}>
            <Typography>{file.name}</Typography>
          </Box>
        ))}
        <Button variant="contained" color="primary" onClick={submitImport} disabled={loadingImport}>
          {loadingImport ? <CircularProgress size={24} /> : 'Import as entries'}
        </Button>
      </Stack>

      <FormControl>
        <Button variant="contained" color="secondary" ref={deleteRef} onClick={() => setDeletionModal(true)}>
          Delete circle
        </Button>
        <Modal open={deletionModal} onClose={() => setDeletionModal(false)} aria-labelledby="modal-title" aria-describedby="modal-description">
          <Box>
            <Typography id="modal-title" variant="h6">
              Engage with Modals
            </Typography>
            <IconButton onClick={() => setDeletionModal(false)}>
              <Close />
            </IconButton>
            <Typography id="modal-description">This action is irreversible!</Typography>
            <Stack direction="row" spacing={2}>
              <Button variant="outlined" onClick={() => setDeletionModal(false)}>
                Cancel
              </Button>
              <Button
                variant="contained"
                color="error"
                onClick={() => {
                  setDeletionModal(false);
                  deleteCircle();
                }}
              >
                Delete
              </Button>
            </Stack>
          </Box>
        </Modal>
      </FormControl>
    </Box>
  );
};
