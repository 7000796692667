import { IUser } from 'models/User';
import { secureFetch } from '../Util';

export type AuthData = {
  user: IUser;
};

const signIn = async (email: string, _password: string): Promise<AuthData> => {
  // get token
  const apiURL = `/api/auth/login`;

  const res = await fetch(apiURL, {
    credentials: 'include',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email: email,
      password: _password,
    }),
  });

  if (res.ok) {
    return setToken(await res.json());
  } else {
    throw new Error(await res.text());
  }
};

const signOut = async (): Promise<void> => {
  // get token
  const apiURL = `/api/auth/logout`;

  const res = await fetch(apiURL, {
    credentials: 'include',
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (!res.ok) {
    throw new Error(await res.text());
  }
};

// refresh the token if valid
const refresh = async (): Promise<AuthData> => {
  const apiURL = `/api/auth/refresh`;

  const res = await secureFetch(apiURL, 'POST');

  if (res.ok) {
    return setToken(await res.json());
  } else {
    throw new Error(await res.text());
  }
};

const register = async (email: string, name: string): Promise<string> => {
  // get token
  const apiURL = `/api/auth/register`;

  const res = await fetch(apiURL, {
    credentials: 'include',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email: email,
      name: name,
    }),
  });

  return await res.text();
};

const setToken = async (loginData: any): Promise<AuthData> => {
  const user = loginData.user;

  return {
    user: user,
  };
};

export const authService = {
  signIn,
  signOut,
  refresh,
  register,
};
