/**
 * Express router paths go here.
 */

export const Paths = {
  Base: '/api',
  Auth: {
    Base: '/auth',
    Login: '/login',
    Register: '/register',
    CompleteRegistration: '/completeRegistration',
    Logout: '/logout',
    Refresh: '/refresh',
    ForgotPassword: '/forgotPassword',
    ResetPassword: '/resetPassword',
  },
  Users: {
    Base: '/users',
    Get: '/all',
    Add: '/add',
    Update: '/update',
    Delete: '/delete/:id',
  },
  Test: {
    Base: '/test',
    Joe: '/joe',
  },
  Journal: {
    Base: '/journal',
    Get: '/get',
    Edit: '/edit',
    Day: '/day',
    Month: '/month',
    Export: '/export/:circle',
    Import: '/import',
    LatestEntry: '/latestEntry',
  },
  Circle: {
    Base: '/circle',
    Get: '/get',
    Invite: '/invite',
    AcceptInvite: '/acceptInvite',
    GetUser: '/getUser',
    Create: '/create',
    Edit: '/edit',
    Delete: '/delete/:circle',
  },
  Prompt: {
    Base: '/prompt',
    Edit: '/edit',
    Get: '/get/:circle/:datestring',
    GetUserPrompt: '/getUserPrompt/:circle',
  },
  AdminPrompt: {
    Base: '/adminPrompt',
    Edit: '/edit',
    Get: '/getAll',
  },
};
