import React, { useCallback, useEffect, useState } from 'react';

import { getCurrentDate, getMonthFromDatestring, secureFetch } from '../../Util';
import { IEntry, IMonthEntries } from 'models/Entry';
import { getPromptForDay } from '../../services/journalService';
import { Paths } from 'models/Paths';
import { Badge, Box, Button, CircularProgress, Stack, Typography } from '@mui/material';
import { DateCalendar, DayCalendarSkeleton, PickersDay, PickersDayProps } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import { useNavigate, useRouteLoaderData } from 'react-router-dom';
import { RouteIds } from '../../Constants';
import { ICircle } from 'models/Circle';
import { AuthData } from '../../services/authService';

const ServerDay = (props: PickersDayProps<DateTime> & { markedDates?: { [key: string]: string[] } }) => {
  const { markedDates = {}, day, outsideCurrentMonth, ...other } = props;

  const isSelected = !props.outsideCurrentMonth && markedDates[day.toISODate() ?? ''];

  return (
    <Badge key={props.day.toString()} overlap="circular" badgeContent={isSelected ? '🌚' : undefined}>
      <PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} />
    </Badge>
  );
};

export const CircleHistory = () => {
  //TODO temp
  const changesMade = false;

  const circle = useRouteLoaderData(RouteIds.Circle) as ICircle;
  const authData = useRouteLoaderData(RouteIds.Root) as AuthData;

  const navigate = useNavigate();

  const [journalEntries, setJournalEntries] = useState<IMonthEntries>({});
  const [loadingJournalEntries, setLoadingJournalEntries] = useState(false);

  const [journalEntriesForDay, setJournalEntriesForDay] = useState<IEntry[]>([]);
  const [loadingJournalEntriesForDay, setLoadingJournalEntriesForDay] = useState(false);

  const [selectedDate, setSelectedDate] = useState('');

  const [prompt, setPrompt] = useState('');

  if (circle === undefined) {
    throw Error();
  }

  const updateJournalEntries = useCallback(
    (dateString: string) => {
      setLoadingJournalEntries(true);
      secureFetch(`/api/${Paths.Journal.Base}/${Paths.Journal.Month}`, 'POST', {
        circle: circle.id,
        month: getMonthFromDatestring(dateString),
      }).then((res) => {
        if (res.ok) {
          res.json().then((entries: IMonthEntries) => {
            setJournalEntries(entries);
            setLoadingJournalEntries(false);
          });
        }
      });
    },
    [circle.id]
  );

  const updateOtherEntries = useCallback(
    (dateString: string) => {
      // get other user's entries for the day
      setLoadingJournalEntriesForDay(true);
      secureFetch(`/api/${Paths.Journal.Base}/${Paths.Journal.Day}`, 'POST', {
        circle: circle.id,
        datestring: dateString,
      }).then(async (res) => {
        if (res.ok) {
          const entries = await res.json();
          setJournalEntriesForDay(entries);
        }
        setLoadingJournalEntriesForDay(false);
      });

      getPromptForDay(circle.id, dateString).then((prompt) => {
        setPrompt(prompt);
      });
    },
    [circle.id]
  );

  const onDatePress = useCallback(
    (dateString: string, changesMade?: boolean) => {
      if (changesMade) {
        return;
      }
      if (dateString === selectedDate) {
        return;
      }
      setSelectedDate(dateString);

      updateOtherEntries(dateString);

      updateJournalEntries(dateString);
    },
    [selectedDate, updateJournalEntries, updateOtherEntries]
  );

  useEffect(() => {
    onDatePress(getCurrentDate(circle.timezone));
  }, [circle.timezone, onDatePress]);

  // construct marked dates object
  // to mark the proper dates on the calendar accordingly
  const markedDates: { [key: string]: string[] } = {};

  for (const user in journalEntries) {
    journalEntries[user].forEach((entry) => {
      const dots = markedDates[entry] ?? [];
      dots.push(user === authData?.user.id ?? '' ? 'userEntry' : 'otherUser');
      markedDates[entry] = dots;
    });
  }

  return (
    <Box>
      <Typography>Welcome to {circle.name}</Typography>
      <DateCalendar
        onChange={(date: DateTime) => {
          onDatePress(date.toISODate() ?? '', changesMade);
        }}
        onMonthChange={(month) => {
          onDatePress(month.toISODate() ?? '', changesMade);
        }}
        loading={loadingJournalEntries}
        renderLoading={() => <DayCalendarSkeleton />}
        slots={{
          day: ServerDay,
        }}
        slotProps={{
          day: {
            markedDates,
          } as any,
        }}
      ></DateCalendar>
      {loadingJournalEntriesForDay ? (
        <CircularProgress />
      ) : (
        <>
          <Typography>Everyone&apos;s entries for {selectedDate}</Typography>
          <Typography>{prompt}</Typography>
          {journalEntriesForDay.length > 0 &&
            journalEntriesForDay.map((entry) => (
              <Stack key={entry.email}>
                <Typography>{entry.email}&apos;s Entry</Typography>
                <Typography>{entry.content} </Typography>
              </Stack>
            ))}
          {journalEntriesForDay.length === 0 && <Typography>Submit an entry to view other&apos;s entries!</Typography>}
        </>
      )}
      <Button onClick={() => navigate(selectedDate)}>Edit your entry for this day</Button>
    </Box>
  );
};
