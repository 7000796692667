import React, { useState } from 'react';
import { useAuth } from '../contexts/Auth';
import { Button, CircularProgress, Stack, TextField, Typography } from '@mui/material';

export const Register = () => {
  const [loading, isLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [loginError, setLoginError] = useState<string | undefined>(undefined);
  const auth = useAuth();

  const register = async () => {
    isLoading(true);
    setLoginError(await auth.register(email, username));
    isLoading(false);
  };

  return (
    <Stack>
      <Typography>Sign in</Typography>
      <Stack>
        <TextField label="Email" type="text" value={email} onChange={(e) => setEmail(e.target.value)} />
        <TextField label="Username" type="text" value={username} onChange={(e) => setUsername(e.target.value)} helperText={loginError} />
      </Stack>
      {loading ? (
        <CircularProgress />
      ) : (
        <Stack>
          <Button onClick={register}>Register</Button>
        </Stack>
      )}
    </Stack>
  );
};
