import { createTheme } from '@mui/material';

export const theme = createTheme({
  cssVariables: {
    colorSchemeSelector: 'class',
  },
  colorSchemes: {
    dark: {
      palette: {
        mode: 'dark',
        primary: {
          main: '#a8e1a6',
        },
        secondary: {
          main: '#FF974D',
        },
        text: {
          primary: '#ffffff',
          secondary: '#fffde2',
        },
      },
    },
    light: {
      palette: {
        mode: 'light',
        action: {
          selectedOpacity: 0.3,
        },
        primary: {
          main: '#a8e1a6',
          contrastText: '#393939',
        },
        secondary: {
          main: '#FF974D',
          contrastText: '#393939',
        },
        text: {
          disabled: '#9C9C9C',
          secondary: '#9C9C9C',
          primary: '#393939',
        },
        background: {
          default: '#fffde2',
        },
      },
    },
  },
  typography: {
    fontFamily: 'Plus Jakarta Sans',
    fontSize: 14,
    h2: {
      fontWeight: 1000,
    },
    h3: {
      fontWeight: 1000,
    },
    h1: {
      fontWeight: 1000,
    },
    h4: {
      fontWeight: 1000,
    },
    h5: {
      fontWeight: 1000,
    },
    h6: {
      fontWeight: 1000,
    },
    body1: {
      fontFamily: 'Open Sans',
    },
    body2: {
      fontFamily: 'Open Sans',
    },
    button: {
      fontFamily: 'Open Sans',
    },
    overline: {
      fontFamily: 'Open Sans',
    },
  },
  shape: {
    borderRadius: 16,
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
});
