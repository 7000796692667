import { DateTime } from 'luxon';

type RequestMethod = 'POST' | 'GET' | 'DELETE';

export const secureFetch = async (
  url: string,
  method: RequestMethod,
  body?: object,
): Promise<Response> => {
  const data: RequestInit = {
    method: method,
  };
  if (method === 'POST') {
    data.headers = { ...data.headers, 'Content-Type': 'application/json' };
  }
  if (body) {
    data.body = JSON.stringify(body);
  }
  return fetch(url, data);
};

export const getCurrentDate = (timezone: string) => {
  const date = DateTime.now().setZone(timezone).toISODate();
  if (date === null) {
    throw Error('Date creation failed! Check timezone');
  }
  return date;
};

export function getTomorrowDate(
  timezone: string,
  dayDifferece: number = 1,
): string {
  const date = DateTime.now()
    .setZone(timezone)
    .plus({ days: dayDifferece })
    .toISODate();
  if (date === null) {
    throw Error('Date creation failed! Check timezone');
  }
  return date;
}

export function getRelativeDate(
  datestring: string,
  timezone: string,
  dayDifferece: number = 1,
): DateTime {
  const date = DateTime.fromISO(datestring, {
    zone: timezone,
  }).plus({ days: dayDifferece });
  if (date === null) {
    throw Error('Date creation failed! Check timezone');
  }
  return date;
}

/**
 * Get month from datestring
 */
export function getMonthFromDatestring(datestring: string): string {
  return datestring.substring(0, 7);
}
