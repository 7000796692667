import React, { useState } from 'react';
import { useAuth } from '../contexts/Auth';
import { Box, Button, CircularProgress, IconButton, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import { ErrorOutline, Visibility, VisibilityOff } from '@mui/icons-material';
import { useSearchParams } from 'react-router-dom';

export const ForgotPassword = () => {
  const [loading, isLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState<string | undefined>(undefined);

  const auth = useAuth();

  const [searchParams, _] = useSearchParams();
  const code = searchParams.get('code');
  const email = searchParams.get('email');

  const resetPassword = async () => {
    const apiURL = `/api/auth/resetPassword`;

    isLoading(true);

    const res = await fetch(apiURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        jwt: code,
        email: email,
        password: password,
      }),
    });

    isLoading(false);

    if (res.ok) {
      setLoginError(await auth.signIn(email ?? '', password));
    } else {
      setLoginError(await res.text());
    }
  };

  return (
    <Box
      p={2}
      border={1}
      borderRadius={2}
      borderColor="grey.300"
      sx={{
        '&.MuiBox-root': {
          borderColor: 'grey.800',
        },
      }}
    >
      <Stack spacing={4}>
        <Typography color="textPrimary" variant="h6">
          Reset password
        </Typography>
        <Stack spacing={1}>
          <Typography variant="body2" color="textSecondary">
            Email
          </Typography>
          <TextField type="text" value={email} disabled fullWidth />
        </Stack>
        <Stack spacing={1}>
          <Typography variant="body2" color="textSecondary">
            New Password
          </Typography>
          <TextField
            type={showPassword ? 'text' : 'password'}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)}>{showPassword ? <Visibility /> : <VisibilityOff />}</IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Stack>
        {loading ? (
          <CircularProgress size={24} />
        ) : (
          <Button variant="contained" onClick={resetPassword}>
            Reset password
          </Button>
        )}
        {loginError && (
          <Box display="flex" alignItems="center" color="error.main">
            <ErrorOutline />
            <Typography variant="body2" color="error">
              {loginError}
            </Typography>
          </Box>
        )}
      </Stack>
    </Box>
  );
};
