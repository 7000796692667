import React, { useEffect, useState } from 'react';
import { secureFetch } from '../Util';
import { Paths } from 'models/Paths';
import { IPrompt } from 'models/Prompt';
import { Box, Button, IconButton, Stack, TextField, Typography } from '@mui/material';
import { Edit } from '@mui/icons-material';

export const AdminDashboard = () => {
  const [prompts, setPrompts] = useState<IPrompt[]>([]);
  const [prompt, setPrompt] = useState('');
  const [selectedPrompt, setSelectedPrompt] = useState<IPrompt | null>(null);

  useEffect(() => {
    fetchPrompts();
  }, []);

  const fetchPrompts = async () => {
    const res = await secureFetch(`/api${Paths.AdminPrompt.Base}${Paths.AdminPrompt.Get}`, 'GET');
    if (res.ok) {
      const fetchedPrompts = await res.json();
      setPrompts(fetchedPrompts);
    }
  };

  const onPromptSubmit = async () => {
    const newPrompt: IPrompt = {
      id: selectedPrompt?.id || '',
      datestring: 'admin',
      circle: 'admin',
      month: 'admin',
      prompt: prompt,
      picked: false,
    };

    await secureFetch(`/api${Paths.AdminPrompt.Base}${Paths.AdminPrompt.Edit}`, 'POST', { prompt: newPrompt });

    setPrompt('');
    setSelectedPrompt(null);
    fetchPrompts();
  };

  const onEditClick = (prompt: IPrompt) => {
    setPrompt(prompt.prompt);
    setSelectedPrompt(prompt);
  };

  const onCancelEdit = () => {
    setPrompt('');
    setSelectedPrompt(null);
  };

  return (
    <Stack p={4}>
      {/* Header Section */}
      <Typography>Admin Dashboard</Typography>
      <Typography>Welcome to the Admin Dashboard. This page is currently under construction.</Typography>

      {/* Prompt Management Section */}
      <Stack>
        {prompts.map((prompt) => (
          <Box
            key={prompt.id}
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            position="relative"
            mb={4} // Add margin-bottom for spacing between boxes
          >
            <Typography mb={2} fontSize="$md" fontWeight="medium">
              {prompt.prompt}
            </Typography>
            {/* Edit Button */}
            <IconButton onClick={() => onEditClick(prompt)}>
              <Edit></Edit>
            </IconButton>
          </Box>
        ))}
      </Stack>

      {/* Prompt Input Section */}
      <Box>
        <Typography>{selectedPrompt ? 'Edit Prompt' : 'Add New Prompt'}</Typography>
        <TextField type="text" value={prompt} onChange={(e) => setPrompt(e.target.value)} placeholder="Enter or edit a prompt..." fullWidth />
        <Stack spacing={2} direction="row" style={{ marginTop: '16px' }}>
          <Button variant="contained" onClick={onPromptSubmit} size="medium">
            {selectedPrompt ? 'Update Prompt' : 'Submit Prompt'}
          </Button>
          {selectedPrompt && (
            <Button variant="outlined" onClick={onCancelEdit} size="medium">
              Cancel
            </Button>
          )}
        </Stack>
      </Box>
    </Stack>
  );
};
