import React, { useEffect, useMemo, useState } from 'react';
import { useAuth } from '../contexts/Auth';
import { getCurrentDate, getRelativeDate, secureFetch } from '../Util';
import { ICircle } from 'models/Circle';
import { Paths } from 'models/Paths';
import { AppBar, Button, CircularProgress, IconButton, Menu, MenuItem, Toolbar, Typography, useColorScheme } from '@mui/material';
import { Outlet, useNavigate, useNavigation, useRouteLoaderData } from 'react-router-dom';
import { AuthData } from '../services/authService';
import { ExpandMore, Logout, Settings } from '@mui/icons-material';
import logo from '../logo.svg';
import { RouteIds } from '../Constants';

export const Root = () => {
  const authData = useRouteLoaderData(RouteIds.Root) as AuthData;
  const auth = useAuth();

  const navigate = useNavigate();
  const navigation = useNavigation();
  const user = useMemo(() => authData.user, [authData]);

  const { mode, setMode } = useColorScheme();

  const [circles, setCircles] = useState<ICircle[]>([]);
  const [loadingCircles, setLoadingCircles] = useState(true);

  const [circleMenuAnchorEl, setCircleMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [inviteMenuAnchorEl, setInviteMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [settingsAnchorEl, setSettingsAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenuOpen = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    setAnchor: React.Dispatch<React.SetStateAction<null | HTMLElement>>
  ) => {
    setAnchor(event.currentTarget);
  };

  const handleMenuClose = (setAnchor: React.Dispatch<React.SetStateAction<null | HTMLElement>>) => {
    setAnchor(null);
  };

  useEffect(() => {
    // get circle objects
    setLoadingCircles(true);
    secureFetch(`/api${Paths.Circle.Base}${Paths.Circle.Get}`, 'POST', {
      circles: [user.circle, ...user.circles],
    }).then((res) => {
      if (res.ok) {
        res.json().then((circles) => {
          setCircles(circles);
          setLoadingCircles(false);
        });
      }
    });
  }, [authData, user.circle, user.circles]);

  const goToCircle = (circle: ICircle) => {
    // get latest entry date
    secureFetch(`/api${Paths.Journal.Base}${Paths.Journal.LatestEntry}`, 'POST', {
      circle: circle.id,
    }).then(async (res) => {
      if (res.ok) {
        const latestEntry: string = await res.json();
        navigate(`/circle/${circle.id}?date=${getRelativeDate(latestEntry, circle.timezone, 1).toISODate() ?? getCurrentDate(circle.timezone)}`);
      }
    });
  };

  const acceptInvite = (circle: string) => {
    secureFetch(`/api${Paths.Circle.Base}${Paths.Circle.AcceptInvite}`, 'POST', {
      circle: circle,
      email: user?.id ?? '',
    }).then((res) => {
      if (res.ok) {
        auth.refreshUser();
      }
    });
  };

  const createCircle = () => {
    secureFetch(`/api${Paths.Circle.Base}${Paths.Circle.Create}`, 'POST', {
      name: user?.name,
      email: user?.id ?? '',
    }).then((res) => {
      if (res.ok) {
        auth.refreshUser();
      }
    });
  };

  return (
    <>
      <AppBar position="static">
        <Toolbar>
          <div style={{ flexGrow: '1' }}>
            <Button onClick={() => navigate('/')} style={{ padding: 0, display: 'flex', flexDirection: 'row' }} color="secondary">
              <img src={logo} alt="logo" style={{ marginRight: '16px', height: '40px' }} />
              <Typography variant="h6">Circles</Typography>
            </Button>
          </div>

          <Button color="inherit" onClick={(event) => handleMenuOpen(event, setCircleMenuAnchorEl)}>
            <Typography>Your circles</Typography>
            <ExpandMore />
          </Button>
          <Menu anchorEl={circleMenuAnchorEl} open={Boolean(circleMenuAnchorEl)} onClose={() => handleMenuClose(setCircleMenuAnchorEl)}>
            {loadingCircles ? (
              <MenuItem>
                <CircularProgress size={24} />
              </MenuItem>
            ) : (
              circles.map((circle) => (
                <MenuItem key={circle.id} onClick={() => goToCircle(circle)}>
                  {circle.name}
                </MenuItem>
              ))
            )}
            <MenuItem onClick={createCircle}>Create new circle</MenuItem>
          </Menu>
          <Button color="inherit" onClick={(event) => handleMenuOpen(event, setInviteMenuAnchorEl)}>
            <Typography>Pending invites</Typography>
            <ExpandMore />
          </Button>
          <Menu anchorEl={inviteMenuAnchorEl} open={Boolean(inviteMenuAnchorEl)} onClose={() => handleMenuClose(setInviteMenuAnchorEl)}>
            {user?.invites.map((invite) => (
              <MenuItem key={invite.circle} onClick={() => acceptInvite(invite.circle)}>
                {invite.circle}
              </MenuItem>
            ))}
            {user.invites.length === 0 && <MenuItem>No pending invites!</MenuItem>}
          </Menu>
          {user?.role === 'admin' && (
            <Button color="inherit" onClick={() => navigate('/AdminDashboard')}>
              Admin Dashboard
            </Button>
          )}
          <IconButton color="inherit" onClick={(e) => handleMenuOpen(e, setSettingsAnchorEl)}>
            <Settings />
          </IconButton>
          <Menu anchorEl={settingsAnchorEl} open={Boolean(settingsAnchorEl)} onClose={() => handleMenuClose(setSettingsAnchorEl)}>
            <MenuItem selected={mode === 'system'} onClick={() => setMode('system')}>
              System Theme
            </MenuItem>
            <MenuItem selected={mode === 'light'} onClick={() => setMode('light')}>
              Light Theme
            </MenuItem>
            <MenuItem selected={mode === 'dark'} onClick={() => setMode('dark')}>
              Dark Theme
            </MenuItem>
          </Menu>
          <Button
            color="inherit"
            onClick={async () => {
              await auth.signOut();
              navigate('/signin');
            }}
          >
            <Logout />
          </Button>
        </Toolbar>
      </AppBar>
      {navigation.state === 'loading' ? <CircularProgress /> : <Outlet />}
    </>
  );
};
