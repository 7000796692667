import React, { createContext, useState, useContext, PropsWithChildren } from 'react';
import { AuthData, authService } from '../services/authService';
import { secureFetch } from '../Util';
import { Paths } from 'models/Paths';

type AuthContextData = {
  authData?: AuthData;
  signIn(email: string, password: string): Promise<string | undefined>;
  register(email: string, name: string): Promise<string | undefined>;
  signOut(): void;
  refreshUser(): void;
};

//Create the Auth Context with the data type specified
//and a empty object
const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [authData, setAuthData] = useState<AuthData>();

  const signIn = async (username: string, password: string) => {
    //call the service passing credential (email and password).
    //In a real App this data will be provided by the user from some InputText components.
    let _authData = undefined;
    try {
      _authData = await authService.signIn(username, password);
    } catch (e) {
      if (e instanceof Error) {
        return e.message;
      }
    }

    if (_authData === undefined) {
      return;
    }

    //Set the data in the context, so the App can be notified
    //and send the user to the AuthStack
    setAuthData(_authData);

    //Persist the data in the Async Storage
    //to be recovered in the next user session.
    sessionStorage.setItem('AuthData', JSON.stringify(_authData));
  };

  const register = async (username: string, name: string) => {
    //call the service passing credential (email and password).
    //In a real App this data will be provided by the user from some InputText components.
    return await authService.register(username, name);
  };

  const signOut = async () => {
    //Remove data from context, so the App can be notified
    //and send the user to the AuthStack
    setAuthData(undefined);

    await authService.signOut();

    //Remove the data from Storage
    //to NOT be recoverede in next session.
    sessionStorage.removeItem('AuthData');
  };

  const refreshUser = async () => {
    const res = await secureFetch(`/api/${Paths.Circle.Base}/${Paths.Circle.GetUser}`, 'POST', {
      email: authData?.user?.id ?? '',
    });
    const user = await res.json();
    const _authData = { ...authData, user: user };
    setAuthData(_authData);

    //Persist the data in the Async Storage
    //to be recovered in the next user session.
    sessionStorage.setItem('AuthData', JSON.stringify(_authData));
  };

  return (
    //This component will be used to encapsulate the whole App,
    //so all components will have access to the Context
    <AuthContext.Provider value={{ authData, signIn, register, signOut, refreshUser }}>{children}</AuthContext.Provider>
  );
};

//A simple hooks to facilitate the access to the AuthContext
// and permit components to subscribe to AuthContext updates
function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export { AuthContext, AuthProvider, useAuth };
