import React, { useState } from 'react';
import { useAuth } from '../contexts/Auth';
import { useSearchParams } from 'react-router-dom';
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

export const CompleteRegistration = () => {
  const [loading, isLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState<string | undefined>(undefined);

  const auth = useAuth();

  const [searchParams, _] = useSearchParams();
  const code = searchParams.get('code');
  const email = searchParams.get('email');

  const completeRegistration = async () => {
    const apiURL = `/api/auth/completeRegistration`;

    isLoading(true);

    const res = await fetch(apiURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        jwt: code,
        password: password,
      }),
    });

    isLoading(false);

    if (res.ok) {
      setLoginError(await auth.signIn(email ?? '', password));
    } else {
      setLoginError(await res.text());
    }
  };

  return (
    <Box
      p={4}
      border={1}
      borderRadius={2}
      borderColor="grey.300"
      sx={{
        '&.MuiBox-root': {
          borderColor: 'grey.800',
        },
      }}
    >
      <Stack spacing={4}>
        <Typography variant="h6" color="textPrimary">
          Complete registration
        </Typography>
        <Stack spacing={2}>
          <TextField label="Email" value={email} disabled variant="outlined" fullWidth />
        </Stack>
        <Stack spacing={2}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel htmlFor="password">New Password</InputLabel>
            <OutlinedInput
              id="password"
              type={showPassword ? 'text' : 'password'}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton aria-label="toggle password visibility" onClick={() => setShowPassword(!showPassword)} edge="end">
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              label="New Password"
            />
          </FormControl>
        </Stack>
        {loading ? (
          <CircularProgress />
        ) : (
          <Button variant="contained" color="primary" onClick={completeRegistration}>
            Complete registration
          </Button>
        )}
        {loginError && <FormHelperText error>{loginError}</FormHelperText>}
      </Stack>
    </Box>
  );
};
