import { Box, Button, Typography } from '@mui/material';
import { ICircle } from 'models/Circle';
import { Outlet, useNavigate, useRouteLoaderData } from 'react-router-dom';
import { RouteIds } from '../Constants';
import { CircleHistory } from './circle/CircleHistory';
import { ArrowBack, Settings } from '@mui/icons-material';

export const Circle = () => {
  const circle = useRouteLoaderData(RouteIds.Circle) as ICircle;
  const navigate = useNavigate();

  return (
    <>
      <Typography variant="h2">Welcome to {circle.name}!</Typography>
      <Box>
        <Button onClick={() => navigate('../')}>
          <ArrowBack />
        </Button>
        <Button onClick={() => navigate('options')}>
          <Settings />
        </Button>
      </Box>

      <CircleHistory />
      <Outlet />
    </>
  );
};
